import { SharedoFetch } from '@sharedo/mobile-core'
import settings from "../../app/settings.js";

function getDocuments(sharedoId) {
    // TODO public API?
    //return SharedoFetch.get(`/api/v1/public/workItem/${id}/relatedDocuments/`);
    return SharedoFetch.get(`/api/sharedo/${sharedoId}/relatedDocuments/`);
}

// Returns document content as Base64 string
function getDocumentContent(repositoryId, repositoryContext, documentId) {
    repositoryContext = fixRepositoryContext(repositoryContext);

    var contextQuery = _toQueryString(repositoryContext);

    var url = `${settings.api}/api/repository/${repositoryId}/${documentId}?NoDownload=1&${contextQuery}`;

    return SharedoFetch.get({ url: url, responseType: "blob" });
}

function getLinkedRepositories(sharedoId) {
    // TODO public API?
    return SharedoFetch.get(`/api/sharedo/${sharedoId}/linkedRepositories?includeAncestors=true`);
}

function uploadAndRelate(sharedoId, formData, repositoryId, repositoryContext, path = "") {
    repositoryContext = fixRepositoryContext(repositoryContext);

    var contextQuery = _toQueryString(repositoryContext);

    // TODO getting "stream closed" error using public API on larger files (>10MB)
    //return SharedoFetch.rawPost(`/api/v1/public/workItem/${sharedoId}/relatedDocuments/`, formData);
    return SharedoFetch.rawPost(`/api/sharedo/${sharedoId}/relatedDocuments/upload/${repositoryId}${path ? "/" + path : ""}?${contextQuery}`, formData);
}

function deleteByIds(sharedoId, docIds) {
    // TODO public API?
    return SharedoFetch.post(`/api/sharedo/${sharedoId}/relatedDocuments/batch-delete`, docIds);
}

// Similar to jQuery.param()
function _toQueryString(obj) {
    return Object
        .keys(obj)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
        .join('&');
}

function fixRepositoryContext(context)
{
    const clone = { ... context };

    // HACK required as property names are camel-cased by Nancy
    if (clone.id) {
        clone.Id = clone.id;
        delete clone.id;
    }

    return clone;
}

export default
    {
        getDocuments,
        getDocumentContent,
        getLinkedRepositories,
        uploadAndRelate,
        deleteByIds,
    };
