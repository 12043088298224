<template>
    <div>
        <div class="float-right header-btns">
            <v-btn-section text v-if="documents.length && !selectDocs && canEdit" @click.stop="toggleSelect(true)">
                Select
            </v-btn-section>
            
            <v-btn-section text v-if="selectDocs" :disabled="selectedCount === 0" color="error" @click.stop="confirmUnlink()">
                Unlink
            </v-btn-section>
            
            <v-btn-section text v-if="selectDocs" color="black" @click.stop="toggleSelect(false)">
                Cancel
            </v-btn-section>
            
            <v-btn-section v-if="uploadAllowed">
                <form ref="uploadForm">
                    <label for="input-upload">
                        <v-icon>{{uploadIcon}}</v-icon>
                    </label>
                    <input
                        id="input-upload"
                        name="files"
                        class="d-none"
                        type="file"
                        multiple="true"
                        @change="upload">
                </form>
            </v-btn-section>
        </div>

        <label class="section-label" v-if="showLabel">{{title}}</label>
        
        <slot name="placeholder" v-if="!documents.length && !loading">
            No documents
        </slot>

        <v-list flat dense class="mx-n3">
            <v-list-item-group>
                <v-list-item
                    v-for="(doc, i) in documents"
                    :key="i"
                >
                    <v-list-item-action v-if="selectDocs">
                        <v-checkbox
                            v-model="doc.selected"
                        ></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-icon @click.stop="viewDocument(doc)">
                        <v-icon v-text="doc.iconClass"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content @click.stop="viewDocument(doc)">
                        <v-list-item-title v-text="doc.title"></v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
import VBtnSection from "../../components/VBtnSection.vue";
import ViewDocumentForm from "./ViewDocumentForm.vue";
import relatedDocumentsAgent from "./relatedDocumentsAgent.js";
import { makeUnique } from "@/util/fileUtil";

export default {
    components: {
        VBtnSection,
        ViewDocumentForm,
    },

    props: {
        id: String,
        showLabel: {
            type: Boolean,
            default: true,
        },
        path: {
            type: String,
            required: false,
            default: ""
        },
        uploadIcon: {
            type: String,
            default: "mdi-upload"
        },
        canEdit: {
            type: Boolean,
            default: true,
        },
    },

    data: function () {
        return {
            loading: true,
            documents: [],
            repository: {},
            selectDocs: false,
        };
    },

    computed: {
        selectedCount () {
            return this.selectedDocuments.length;
        },

        selectedDocuments () {
            return this.documents.filter(d => d.selected);
        },

        title () {
            if (this.selectDocs) {
                return this.selectedCount
                    ? `${this.selectedCount} Document${this.selectedCount > 1 ? 's' : ''} Selected`
                    : "Select Documents";
            }
            else {
                return "Documents";
            }
        },

        uploadAllowed () {
            return !this.selectDocs && this.repository && !!this.repository.repositoryId && this.canEdit;
        },
    },

    watch: {
        uploadAllowed: function (val) {
            this.$emit("uploadAllowedChanged", val);
        },
    },

    mounted: function () {
        this.load();
    
        relatedDocumentsAgent.getLinkedRepositories(this.id).then(linked => {
            this.repository = linked[0];
        }).catch(console.error);
    },

    methods: {
        load () {
            this.loading = true;
            relatedDocumentsAgent.getDocuments(this.id).then(docs => {
                this.loading = false;

                // Map
                docs.forEach(d => Object.assign(d, { selected: false }));

                // (Order is user-defined, don't change)

                this.documents = docs;
            }).catch(console.error);
        },

        viewDocument (doc) {
            if (this.selectDocs) {
                doc.selected = !doc.selected;
                return;
            }

            this.$coreUi.dialog(ViewDocumentForm,
                {
                    ...doc
                });
        },

        upload (event) {
            var input = event.target;

            var nFiles = 0;
            var filename = null;

            var formData = new FormData();

            Array.from(input.files).forEach((f) => {
                nFiles++;

                filename = makeUnique(f.name, this.documents.map(doc => doc.title));

                formData.append("file", f, filename);
            });

            var spinner = this.$coreUi.loading();
            var repo = this.repository;

            relatedDocumentsAgent.uploadAndRelate(this.id, formData, repo.repositoryId, repo.repositoryContext, this.path).then(doc => {
                
                if (nFiles === 1)
                    this.$coreUi.toast(`Added '${filename.length > 20 ? filename.substring(0,20) + "..." : filename}'`);
                else
                    this.$coreUi.toast(`Added ${nFiles} files`);

                spinner.dismiss();
                this.load();
            }).catch(console.error);
        },

        confirmUnlink () {
            this.$coreUi.messageBox({
                title: `Unlink ${this.selectedCount} document(s)`,
                message: "Are you sure?",
                btns: [
                    {text: "Cancel"},
                    {text: "Unlink", color: "error", handler: () => this.deleteSelected() }
                ]
            });
        },

        deleteSelected () {
            var docIds = this.selectedDocuments.map(d => d.id);

            var spinner = this.$coreUi.loading();

            relatedDocumentsAgent.deleteByIds(this.id, docIds).then(() => {
                spinner.dismiss();
                this.selectDocs = false;
                
                this.load();
            }).catch(console.error);
        },

        toggleSelect (on) {
            this.selectDocs = on;
            this.documents.forEach(d => d.selected = false);
        },
    },
};
</script>

