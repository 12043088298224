<template>
    <v-card>
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>View document</v-toolbar-title>

            <v-spacer class="mr-3" />
        </v-toolbar>

        <v-card-title>
            <span>
                {{title}}

                <!-- Doc ID tooltip if different to title -->
                <v-tooltip bottom v-if="showInfo">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                        >mdi-information-outline</v-icon>
                    </template>
                    <span>{{documentId}}</span>
                </v-tooltip>
            </span>
        </v-card-title>

        <div class="mx-6" v-show="loading">
            <v-skeleton-loader type="image"></v-skeleton-loader>
        </div>

        <div class="mx-6" v-show="!loading">
            <img
                v-bind:src="fileBase64"
                @error="error"
                v-if="isImage && !failed"
                class="flex min-w-full"
            >

            <video
                controls
                autoplay
                v-if="isVideo && fileBase64"
                class="flex min-w-full"
            >
                <source :type="videoMimeType" v-bind:src="fileBase64">
                Your browser does not support the video tag.
            </video>

            <v-alert
                type="warning"
                v-if="failed || unsupportedType"
            >Preview not supported for this file type.</v-alert>
        </div>
        
    </v-card>
</template>

<script>
import relatedDocumentsAgent from "../RelatedDocuments/relatedDocumentsAgent";

export default {
    props: {
        id: String,
        title: String,
        documentId: String,
        repositoryId: String,
        repositoryContext: String,
    },
    data: function () {
        return {
            document: {
                id: this.id,
            },
            url: null,
            fileBase64: null,
            videoMimeType: "video/mp4",     // should work in most cases
            failed: false,
            loading: true,
        };
    },
    computed: {
        showInfo () {
            return this.documentId && this.documentId !== this.title;
        },
        extension () {
            return (this.documentId || "").split(".").pop().toLowerCase();
        },
        isImage () {
            return ["gif", "png", "bmp", "jpg", "jpeg", "psd", "heic", "webp"].indexOf(this.extension) !== -1;
        },
        isVideo () {
            return ["mpg", "mp4", "avi", "mov", "3gp"].indexOf(this.extension) !== -1;
        },
        unsupportedType () {
            return !this.isImage && !this.isVideo;
        },
    },
    mounted: function() {

        if (this.unsupportedType) {
            this.error();
            return;
        }

        var repoContext = JSON.parse(this.repositoryContext || "{}");

        // Get doc URL
        relatedDocumentsAgent.getDocumentContent(this.repositoryId, repoContext, this.documentId).then(blob => {
            
            // Blob => Base64 string
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            
            reader.onloadend = () => {
                this.fileBase64 = reader.result;
                this.loading = false;
            };

            reader.onerror = () => {
                error();
            };

        }).catch(err => {
            console.error(err);
            this.error();
        });
    },
    methods: {
        error() {
            this.failed = true;
            this.loading = false;
        },

        close (result) {
            this.$emit("close", result);
        },
    },
};
</script>
